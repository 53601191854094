import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../../../settings'

// const test = localStorage.getItem('userData')
// console.log(JSON.parse(test).accessToken)

// **********************************
//    API ENDPOINT CONFIGURATION
//      - authorization token
//      - invalidation
// **********************************
export const partnersApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/api`,
    prepareHeaders: (headers, { getState }) => {
      const user = localStorage.getItem('userData')
      const token = JSON.parse(user).accessToken
      // const token = getState().auth.userData.accessToken
      if (token) {
        headers.set('Authorization', `Token ${token}`)
        headers.set('Content-Type', 'application/json; charset=utf8')
      }
      return headers
    }
  }),
  reducerPath: 'partnersApi',
  tagTypes: [
    'Partners',
    'Vendor Representatives',
    'Clients',
    'Client Representatives'
  ],
  endpoints: () => ({})
})

// **********************************
//           API ENDPOINTS
// **********************************
const api = partnersApi.injectEndpoints({
  endpoints: (build) => ({
    getAllPartners: build.query({
      query: () => '/partner/vendor',
      providesTags: (result) => {
        return result
          ? [
              ...result.map(({ id }) => ({ type: 'Partners', id })),
              { type: 'Partners', id: 'LIST' }
            ]
          : [{ type: 'Partners', id: 'LIST' }]
      }
    }),
    getPartner: build.query({
      query: (partnerId) => `/partner/vendor/${partnerId}`,
      providesTags: (result) => [{ type: 'Partners', id: result?.id }]
    }),
    getAllClients: build.query({
      query: () => '/partner/client',
      providesTags: (result) => {
        return result
          ? [
              ...result.map(({ id }) => ({ type: 'Clients', id })),
              { type: 'Clients', id: 'LIST' }
            ]
          : [{ type: 'Clients', id: 'LIST' }]
      }
    }),
    getClient: build.query({
      query: (clientId) => `/partner/client/${clientId}`,
      providesTags: (result) => [{ type: 'Clients', id: result.id }]
    }),
    getDriver: build.query({
      query: (clientId) => `/partner/driver/${clientId}`,
      providesTags: (result) => [{ type: 'Clients', id: result.id }]
    }),
    addAccount: build.mutation({
      query: (classification) => ({
        url: `/configuration/partner/vendor`,
        method: 'POST',
        body: classification
      }),
      providesTags: (result) => [{ type: 'Partners', id: result.id }],
      invalidatesTags: [{ type: 'Partners', id: 'LIST' }]
    }),
    addPartner: build.mutation({
      query: (data) => ({
        url: `/partner/vendor`,
        method: 'POST',
        body: data
      }),
      providesTags: (result) => [{ type: 'Partners', id: result.id }],
      invalidatesTags: [{ type: 'Partners', id: 'LIST' }]
    }),
    addClient: build.mutation({
      query: (data) => ({
        url: `/partner/client`,
        method: 'POST',
        body: data
      }),
      providesTags: (result) => [{ type: 'Clients', id: result.id }],
      invalidatesTags: [{ type: 'Clients', id: 'LIST' }]
    }),
    addDriver: build.mutation({
      query: (data) => ({
        url: `/partner/driver`,
        method: 'POST',
        body: data
      }),
      providesTags: (result) => [{ type: 'Clients', id: result.id }],
      invalidatesTags: [{ type: 'Clients', id: 'LIST' }]
    }),
    deletePartner: build.mutation({
      query: (id) => ({
        url: `/partner/vendor/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: 'Partners', id: 'LIST' }]
    }),
    deleteClient: build.mutation({
      query: (id) => ({
        url: `/partner/client/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: 'Clients', id: 'LIST' }]
    }),
    updatePartner: build.mutation({
      query: (data) => ({
        url: `/partner/vendor/${data.id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: [{ type: 'Partners', id: 'LIST' }]
    }),
    updateClient: build.mutation({
      query: (data) => ({
        url: `/partner/client/${data.id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: [{ type: 'Clients', id: 'LIST' }]
    }),
    getVendorRepresentative: build.query({
      query: (partnerId) => `/partner/vendor/${partnerId}`,
      providesTags: (result) => [
        { type: 'Vendor Representatives', id: result?.id }
      ]
    }),
    addVendorRepresentative: build.mutation({
      query: (data) => ({
        url: `/partner/vendor/${data.partnerId}/representative`,
        method: 'POST',
        body: data.payload
      }),
      providesTags: (result) => [
        { type: 'Vendor Representatives', id: result.id }
      ],
      invalidatesTags: [{ type: 'Vendor Representatives', id: 'LIST' }]
    }),
    addClientRepresentative: build.mutation({
      query: (data) => ({
        url: `/partner/client/${data.partnerId}/representative`,
        method: 'POST',
        body: data.payload
      }),
      providesTags: (result) => [
        { type: 'Client Representatives', id: result.id }
      ],
      invalidatesTags: [{ type: 'Client Representatives', id: 'LIST' }]
    }),
    getAllVendorRepresentatives: build.query({
      query: (partnerId) => `/partner/vendor/${partnerId}/representative`,
      providesTags: (result) => {
        return result
          ? [
              ...result.map(({ id }) => ({
                type: 'Vendor Representatives',
                id
              })),
              { type: 'Vendor Representatives', id: 'LIST' }
            ]
          : [{ type: 'Vendor Representatives', id: 'LIST' }]
      }
    }),
    getAllClientRepresentatives: build.query({
      query: (partnerId) => {
        if (partnerId === undefined) {
          return undefined;
        }
        return `/partner/client/${partnerId}/representative`
      },
      providesTags: (result) => {
        return result
          ? [
              ...result.map(({ id }) => ({
                type: 'Client Representatives',
                id
              })),
              { type: 'Client Representatives', id: 'LIST' }
            ]
          : [{ type: 'Client Representatives', id: 'LIST' }]
      }
    })
  })
})

export const { endpoints } = api
export const {
  useGetClientQuery,
  useGetDriverQuery,
  useGetAllClientsQuery,
  useAddClientMutation,
  useAddDriverMutation,
  useDeleteClientMutation,
  useGetPartnerQuery,
  useLazyGetPartnerQuery,
  useGetAllPartnersQuery,
  useDeletePartnerMutation,
  useAddAccountMutation,
  useAddPartnerMutation,
  useGetVendorRepresentativeQuery,
  useUpdatePartnerMutation,
  useAddVendorRepresentativeMutation,
  useAddClientRepresentativeMutation,
  useGetAllClientRepresentativesQuery,
  useGetAllVendorRepresentativesQuery
} = api
