import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { BASE_URL } from '../../../../settings'

export const apiEndpoint = `${BASE_URL}/api`

const authAxios = axios

export const getTemplates = createAsyncThunk(
  'appServiceTemplate/getTemplates',
  async () => {
    try {
      const response = await authAxios.get(
        `${apiEndpoint}/vehicle/service_templates`
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
)

export const getTemplate = createAsyncThunk(
  'appServiceTemplate/getTemplate',
  async (id) => {
    try {
      const response = await authAxios.get(
        `${apiEndpoint}/vehicle/service_templates/${id}`
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
)
export const createTemplate = createAsyncThunk(
  'appServiceTemplate/createTemplate',
  async (data) => {
    try {
      const response = await authAxios.post(
        `${apiEndpoint}/vehicle/service_templates`,
        data
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
)
export const editTemplate = createAsyncThunk(
  'appServiceTemplate/editTemplate',
  async (data) => {
    try {
      const response = await authAxios.put(
        `${apiEndpoint}/vehicle/service_templates/${data.id}`,
        data
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
)
export const deleteTemp = createAsyncThunk(
  'appServiceTemplate/deleteTemp',
  async (id) => {
    try {
      const response = await authAxios.delete(`${apiEndpoint}/vehicle/service_templates/${id}`)
      return id
    } catch (error) {
      return error
    }
  }
)
export const createTemplateItem = createAsyncThunk(
  'appServiceTemplate/createTemplateItem',
  async (data) => {
    try {
      const response = await authAxios.post(
        `${apiEndpoint}/vehicle/service_template_items`,
        data
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
)
export const deleteTemplateItem = createAsyncThunk(
  'appServiceTemplate/deleteTemplateItem',
  async (id) => {
    try {
      const response = await authAxios.delete(
        `${apiEndpoint}/vehicle/service_template_items/${id}`
      )
      return id
    } catch (error) {
      console.log(error)
    }
  }
)
export const getItems = createAsyncThunk(
  'appServiceTemplate/getItems',
  async () => {
    try {
      const response = await authAxios.get(
        `${apiEndpoint}/vehicle/service_items`
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
)

export const appServiceTemplateSlice = createSlice({
  name: 'serviceTemplates',
  initialState: {
    data: [],
    allData: [],
    selectedTemplate: {},
    serviceItems: [],
    isLoading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(getTemplates.pending, (state, action) => {
      state.isLoading = true;
    })
    .addCase(getTemplates.fulfilled, (state, action) => {
      state.allData = action.payload;
      state.isLoading = false;
    })
    .addCase(getTemplate.fulfilled, (state, action) => {
      state.selectedTemplate = action.payload;
      state.isLoading = false;
    })
    .addCase(createTemplate.fulfilled, (state, action) => {
      state.allData.push(action.payload)
      state.selectedTemplate = action.payload
      state.isLoading = false;
    })
    .addCase(editTemplate.fulfilled, (state, action) => {
      state.selectedTemplate = action.payload
      state.isLoading = false;
    })
    .addCase(createTemplateItem.fulfilled, (state, action) => {
      state.selectedTemplate.items.push(action.payload)
      state.isLoading = false;
    }).addCase(deleteTemplateItem.fulfilled, (state, action) => {
      try {
        const filtered = state.selectedTemplate.items.filter(
          (item) => item.id !== action.payload
        )
        state.selectedTemplate.items = [...filtered]
      } catch (error) {
        console.log(error)
      }
    })
    .addCase(deleteTemp.fulfilled, (state, action) => {
      try {
        const filtered = state.allData.filter(
          (template) => template.id !== action.payload
        )
        state.allData = [...filtered]
      } catch (error) {
        console.log(error)
      }
    })
    .addCase(getItems.pending, (state, action) => {
      state.isLoading = true;
    })
    .addCase(getItems.fulfilled, (state, action) => {
      state.serviceItems = action.payload
      state.isLoading = false;
    })
  }
});

export default appServiceTemplateSlice.reducer;