// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { BASE_URL } from '../../../settings'
import { objectToQueryString } from '../../../utility/Utils'
export const apiEndpoint = `${BASE_URL}/api`

const authAxios = axios

export const getRegistrations = createAsyncThunk('appRegistrations/getRegistrations', async () => {
    try {
        return await authAxios.get(`${apiEndpoint}/vehicle/registrations`)
    } catch (error) {
        console.log(error)
    }
})
export const getRegistrationsV2 = createAsyncThunk(
    'appRegistrations/getRegistrationsV2',
    async (queryParams = { page_size: 10, page: 1 }, { rejectWithValue }) => {
        const queryString = objectToQueryString(queryParams)
        try {
            const response = await authAxios.get(
            `${apiEndpoint}/vehicle/v2/registrations?${queryString}`
            )
            return {
            data: response.data.results,
            allData: response.data.results,
            count: response.data.count,
            totalPages: Math.ceil(response.data.count / queryParams.page_size),
            vehicles: response.data.results
            }
        } catch (error) {
            console.log(error)
            return rejectWithValue(error.response?.data || 'Unknown error')
        }
        }
  )

export const appRegistrationsSlice = createSlice({
    name: 'appRegistrations',
    initialState: {
        data: [],
        isLoading: false,
        allData: [],
        total: 0,
        params: {},
        count: 0
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getRegistrations.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getRegistrations.fulfilled, (state, action) => {
                try {
                    state.data = action.payload.data
                    state.isLoading = false
                } catch (error) {
                    console.log(error)
                }
            })
            .addCase(getRegistrationsV2.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getRegistrationsV2.fulfilled, (state, action) => {
                try {
                    state.data = action.payload.data
                    state.allData = action.payload.allData
                    state.total = action.payload.totalPages
                    state.count = action.payload.count
                    state.params = action.payload.params
                    state.isLoading = false
                } catch (error) {
                    console.log(error)
                }
                state.isLoading = false
            })
            
    }
})

export default appRegistrationsSlice.reducer