// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { BASE_URL } from '../../../settings'

export const apiEndpoint = `${BASE_URL}/api`

const authAxios = axios

export const getServices = createAsyncThunk('appServices/getServices', async () => {
    try {
        return await authAxios.get(`${apiEndpoint}/vehicle/service`)
    } catch (error) {
        console.log(error)
    }
})

export const appServicesSlice = createSlice({
    name: 'appServices',
    initialState: {
        data: [],
        isLoading: false
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getServices.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getServices.fulfilled, (state, action) => {
                try {
                    state.data = action.payload.data
                    state.isLoading = false
                } catch (error) {
                    console.log(error)
                }
            })
    }
})

export default appServicesSlice.reducer